<template>
  <div>
    <b-row class="justify-content-between">
      <b-col>
        <div
          v-if="data.description == 'arrival'"
          style="color: #308F5D"
          class="fw-bold"
        >
          Arrive at
        </div>
        <div
          v-if="data.description == 'departure'"
          style="color: #2654AB"
          class="fw-bold"
        >
          Depart from
        </div>
        <div>{{ data.place?.name || '-' }}</div>
        <div class="fw-bold">
          {{ dateTime }}
        </div>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <div
          v-if="data.place?.category"
          class="my-auto text-center text-capitalize px-2"
          style="border: 1px solid; border-radius: 20px;"
          :style="`border-color: ${getSiteColor(data.place.category)}`"
        >
          {{ capitalize(data.place.category) }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { capitalize, getSiteColor } from '@/utils/helper';
export default {
  props: {
    data: {
      type: Object,
      default() {
        return {
          description: ''
        }
      }
    },
  },
  computed: {
    dateTime() {
      if(this.data.created_at) {
        const date = new Date(this.data.created_at)
        return format(date, "dd MMM yyyy HH:mm")
      } return '-'
    }
  },
  methods: {
    capitalize,
    getSiteColor
  },
}
</script>

<style lang="scss" scoped>

</style>
