<template>
  <div>
    <b-row class="justify-content-between">
      <b-col sm="9">
        {{ dateTime }}
      </b-col>
      <b-col
        sm="3"
        class="text-end"
      >
        <div
          v-if="dateTime != '-'"
          class="my-auto"
          :style="`color: ${isAway ? '#FF7A00' : '#6100FF'}`"
        >
          <i class="bx bxs-circle" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { format, differenceInHours } from 'date-fns';

export default {
  props: {
    fleetDetail: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  computed: {
    dateTime() {
      if(this.fleetDetail.last_location?.created_at) {
        const date = new Date(this.fleetDetail.last_location.created_at)
        return format(date, "dd MMM yyyy HH:mm")
      } return '-'
    },
    isAway() {
      if(this.fleetDetail.last_location?.created_at) {
        return differenceInHours(new Date(), new Date(this.fleetDetail.last_location.created_at)) > 1
      } return true
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
