<!-- eslint-disable vue/no-unused-vars -->
<template>
  <Layout :items="items">
    <SplitHeader :left-size="6">
      <template #left>
        <div class="d-flex justify-content-between w-100 my-auto">
          <h4 class="d-flex mb-sm-0 align-items-center">
            FLEET REALTIME STATUS REPORT
          </h4>
        </div>
      </template>
      <template #right>
        <div class="d-lg-flex gap-2 w-100 my-auto">
          <b-col class="mb-lg-0 mb-3">
            <FormInputSelect
              v-model="filter.fleet_type"
              :options="typeOptions"
              label="Fleet Type"
            />
          </b-col>
          <b-col class="mb-lg-0 mb-3">
            <FormInputSelect
              v-model="filter.last_place_log_status"
              :options="fleetActivityOptions"
              label="Activity"
              :has-icon="true"
            />
          </b-col>
          <b-col class="mb-lg-0 mb-3">
            <FormInputSelect
              v-model="filter['can_bus[ignition]']"
              :options="fleetIgnitionOptions"
              label="Ignition"
              :has-icon="true"
            />
          </b-col>
          <b-col class="mb-lg-0 mb-3">
            <FormInputSelect
              v-model="filter.can_bus_available"
              :options="canBusOptions"
              label="CAN Bus"
            />
          </b-col>
        </div>
      </template>
    </SplitHeader>
    <b-row>
      <b-col
        style="margin-top: 2.9rem;"
        lg
        class="px-0 px-lg-2"
      >
        <div>
          <Pagination
            :meta-data="fleetsMeta"
            @page-change="handlePageChange"
            @per-page-change="handlePerPageChange"
          >
            <template #button>
              <b-col>
                <SearchBar
                  placeholder="Search Vehicle Number"
                  @do-search="(val) => filter.keyword = val"
                  @reset-search="filter.keyword = null"
                />
              </b-col>
            </template>
            <template #table>
              <DataTable
                :fields="tableFields"
                :items="fleets"
                :allow-local-sort="false"
                :is-loading="isLoading"
                :has-unit="true"
                @sort-changed="sortData"
              >
                <template #cell(vehicle_number)="{ row }">
                  <router-link
                    :to="`/dashboard?fleet=${row.item.id}`"
                    class="link-item-table custom-link"
                    v-html="highlightString(row.item.vehicle_number, filter.keyword)"
                  />
                </template>
                <template #cell(logo)="{ row }">
                  <div class="text-center">
                    <img
                      v-if="row.item.brand != null"
                      :src="getFleetBrandIcon(row.item.brand)"
                      alt=""
                      style="max-height: 20px; max-width: 28px;"
                      :title="row.item.brand"
                    >
                    <span v-else>-</span>
                  </div>
                </template>
                <template #cell(latest_activity)="{ row }">
                  <FleetDestination :data="row.item.last_place_log || {}" />
                </template>
                <template #cell(latest_update)="{ row }">
                  <FleetLatestUpdate :fleet-detail="row.item || {}" />
                </template>
                <template #cell(ignition)="{ row }">
                  <div class="text-center">
                    <img
                      v-if="row.item.last_location?.can_bus?.ignition != null"
                      :src="row.item.last_location?.can_bus?.ignition ? require('@/assets/images/icons/ignition-on.svg') : require('@/assets/images/icons/ignition-off.svg')"
                      class="my-auto mx-auto icon-table"
                    > <span v-else>-</span>
                  </div>
                </template>
                <template #cell(parking_brake)="{ row }">
                  <div class="text-center">
                    <img
                      v-if="row.item.last_location?.can_bus?.parking_brake != null"
                      :src="row.item.last_location?.can_bus?.parking_brake ? `${require(`@/assets/images/icons/parking-brake-on.svg`)}` : `${require(`@/assets/images/icons/parking-brake-off.svg`)}`"
                      class="my-auto mx-auto icon-table"
                    > <span v-else>-</span>
                  </div>
                </template>
                <template #cell(malfunction_indicator)="{ row }">
                  <div class="text-center">
                    <div v-if="row.item.last_location?.can_bus?.malfunction_indicator_lamp_status != null">
                      <img
                        v-if="row.item.last_location?.can_bus?.malfunction_indicator_lamp_status == 1"
                        :src="require(`@/assets/images/icons/malfunction-indicator-on.svg`)"
                        class="my-auto mx-auto icon-table"
                      >
                      <img
                        v-else-if="row.item.last_location?.can_bus?.malfunction_indicator_lamp_status == 2"
                        :src="require(`@/assets/images/icons/malfunction-indicator-error.svg`)"
                        class="my-auto mx-auto icon-table"
                      >
                      <img
                        v-else-if="row.item.last_location?.can_bus?.malfunction_indicator_lamp_status == 3"
                        :src="require(`@/assets/images/icons/malfunction-indicator-unavailable.svg`)"
                        class="my-auto mx-auto icon-table"
                      >
                      <img
                        v-else
                        :src="require(`@/assets/images/icons/malfunction-indicator-off.svg`)"
                        class="my-auto mx-auto icon-table"
                      >
                    </div>
                    <span v-else>-</span>
                  </div>
                </template>
                <template #cell(speed)="{ row }">
                  {{ row.item.last_location?.can_bus?.wheel_based_vehicle_speed || row.item.last_location?.can_bus?.gps_vehicle_speed || '-' }}
                </template>
                <template #cell(odometer)="{ row }">
                  {{ row.item.last_location?.can_bus?.total_vehicle_distance || '-' }}
                </template>
              </DataTable>
              <div
                v-if="!fleets.length"
                class="text-small text-center mb-3"
              >
                {{ filter.keyword ? 'Sorry, no fleet match your search.' : 'Oops! There is no fleet yet.' }}
              </div>
            </template>
          </Pagination>
        </div>
      </b-col>
    </b-row>
  </Layout>
</template>

<script>
  import { mapGetters } from "vuex";
  import Layout from "@/layouts/main.vue";

  import SplitHeader from '@/components/SplitHeader.vue';
  import FormInputSelect from '@/components/FormInputSelect.vue';
  
  import FleetDestination from '@/components/fleet/FleetDestination.vue';
  import FleetLatestUpdate from '@/components/fleet/FleetLatestUpdate.vue';

  import Pagination from '@/components/Pagination.vue';
  import SearchBar from '@/components/SearchBar.vue';
  import DataTable from '@/components/DataTable.vue';
  
  import { highlightString, getFleetBrandIcon } from '@/utils/helper';
  import { utcOffset, fleetTypeOptions, fleetActivityOptions, fleetIgnitionOptions, fleetCanBusOptions } from '@/utils/constant';

  export default {
    components: {
      Layout,
      SplitHeader,
      Pagination,
      SearchBar,
      DataTable,
      FormInputSelect,
      FleetDestination,
      FleetLatestUpdate,
    },
    data() {
      return {
        items: [{
            text: "Fleet Management",
            href: "/fleet",
          },
        ],
        
        fleetActivityOptions,
        fleetIgnitionOptions,
        typeOptions: [
          { label: 'All', value: "" },
          ...fleetTypeOptions
        ],
        canBusOptions: [
          { label: 'All', value: "" },
          ...fleetCanBusOptions
        ],

        isLoading: false,
        fleets: [],
        fleetsMeta: {},

        tableFields: [{
            key: 'logo',
            label: '',
            unit: ' ',
          }, {
            key: 'vehicle_number',
            label: 'Vehicle Number',
            unit: 'No. Polisi',
            sortable: true,
          }, {
            key: 'fleet_type',
            label: 'Type',
            sortable: true,
            thStyle: {
              minWidth: '4rem',
            },
            sortable: true,
          }, {
            key: 'driver_name',
            label: 'Driver',
            unit: 'Name',
            class: 'fit',
            thStyle: {
              minWidth: '8rem',
            },
          }, {
            key: 'latest_activity',
            label: 'Latest Activity',
            unit: `Time ${utcOffset}`,
            thStyle: {
              minWidth: '15rem'
            },
          }, {
            key: 'latest_destination',
            label: 'Latest Destination',
            unit: `Estimated Time Arrival ${utcOffset}`,
            thStyle: {
              minWidth: '15rem'
            },
          }, {
            key: 'latest_update',
            label: 'Latest Update',
            unit: `${utcOffset}`,
            sortable: true,
            thStyle: {
              minWidth: '12rem'
            },
          }, {
            key: 'ignition',
            label: 'IGN',
          }, {
            key: 'parking_brake',
            label: 'Parking',
            unit: 'Brake'
          }, {
            key: 'malfunction_indicator',
            label: 'MIL',
          }, {
            key: 'speed',
            label: 'Speed',
            unit: 'km/h'
          }, {
            key: 'odometer',
            label: 'Odometer',
            unit: 'km'
          },
        ],

        filter: {
          keyword: null,
          type: '',
          last_place_log_status: '',
          can_bus_available: '',
          per_page: 10,
          page: 1,
          sort_by: 'last_location.created_at',
          direction: 'desc',
          'can_bus[ignition]': '',
        },
      };
    },
    computed: {
      ...mapGetters('organization', ['userRole']),
      selectedOrganization() {
        return this.$store.state.organization.selectedOrganization;
      },
      processedFilter() {
        if(this.filter['can_bus[ignition]']) {
          return this.filter
        } else {
          const newFilter = Object.fromEntries(
            Object.entries(this.filter).filter(([key]) => !['can_bus[ignition]'].includes(key))
          );
          return newFilter
        }
      }
    },
    watch: {
      selectedOrganization: {
        immediate: true,
        handler(organizationId) {
          if (organizationId) {
            this.getFleets()
          }
        },
      },
      filter: {
        deep: true,
        immediate: true,
        handler() {
          this.$router.push({ query: { ...this.filter } })
          this.getFleets()
        }
      }
    },
    methods: {
      highlightString, 
      getFleetBrandIcon,
      async getFleets() {
        try {
          this.isLoading = true;
          
          const { data } = await this.$api.getOrganizationDevices(this.selectedOrganization, { ...this.processedFilter, load_last_place_log: 1 });

          this.fleets = data.data;
          this.fleetsMeta = data.meta;
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      },
      sortData(sort) {
        this.filter.sort_by = sort.sortBy
        this.filter.direction = sort.direction
        if(sort.sortBy == 'latest_update') {
          this.filter.sort_by = 'last_location.created_at'
        }
      },
      handlePageChange(page) {
        this.filter.page = page
      },
      handlePerPageChange(perPage) {
        this.filter.page = 1
        this.filter.per_page = perPage
      }
    },
  };
</script>
<style scoped>
.icon-table{
  max-height: 20px; max-width: 28px;
}
</style>